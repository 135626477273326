import { useEffect, useState, FunctionComponent } from 'react'
import { snackbarShowMessage } from '@/components/Snackbar'
import sendEmailFE from '../utils/sendEmailFE'
import classnames from 'classnames'

import styles from './index.module.scss'

const RWA20WebsiteDefault: FunctionComponent = () => {
  return (
    <div className={styles.rwa20WebsiteDefault}>
      <div className={styles.header}>
        <div className={styles.vectorParent}>
          <svg
            className={styles.vectorIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="33"
            viewBox="0 0 49 33"
            fill="none"
          >
            <path
              d="M32.7462 24.6912V32.25H32.7519C41.6025 32.25 48.75 25.2062 48.75 16.5C48.75 7.81045 41.6024 0.750002 32.7519 0.750002C29.7966 0.750002 27.0267 1.54358 24.6429 2.93218L24.6417 2.93289C22.1409 4.37074 20.0765 6.46972 18.6835 8.9816C17.496 11.1443 16.7975 13.617 16.7558 16.25H24.0625H24.3125H24.4413C24.552 12.6179 27.0654 9.58641 30.4647 8.63797C31.1901 8.41193 31.9645 8.30876 32.7519 8.30876C37.3536 8.30876 41.0663 11.9767 41.0663 16.5C41.0663 21.0233 37.3536 24.6912 32.7519 24.6912L32.7462 24.6912ZM30.3164 24.0187C31.504 21.856 32.2025 19.3831 32.2442 16.75H24.9375H24.6875H24.5587C24.448 20.3832 21.9339 23.4318 18.5327 24.3796C17.8098 24.5878 17.0365 24.6912 16.2481 24.6912C11.6638 24.6912 7.93372 21.0236 7.93372 16.5C7.93372 12.0589 11.5289 8.44285 15.9981 8.31241V0.751888C7.27975 0.883602 0.25 7.89274 0.25 16.5C0.25 25.1894 7.41453 32.25 16.2481 32.25C19.2208 32.25 22.0076 31.4563 24.391 30.068C26.8761 28.6128 28.9406 26.5133 30.3164 24.0187Z"
              fill="url(#paint0_radial_625_1815)"
              stroke="url(#paint1_linear_625_1815)"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <defs>
              <radialGradient
                id="paint0_radial_625_1815"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(3.07541 16.5596) rotate(15.1119) scale(47.556 41.4133)"
              >
                <stop stop-color="#8C96A8" />
                <stop offset="0.422395" stop-color="#5D7297" />
                <stop offset="1" stop-color="#363E64" />
              </radialGradient>
              <linearGradient
                id="paint1_linear_625_1815"
                x1="24.5"
                y1="32.5"
                x2="24.5"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" stop-opacity="0.13" />
                <stop offset="1" stop-color="white" stop-opacity="0.06" />
              </linearGradient>
            </defs>
          </svg>
          <div className={styles.rwa20}>RWA-20</div>
        </div>
        <div className={styles.fairLaunchWrapper}>
          <div className={styles.fairLaunch}>
            <a href="#fairLaunch">Fair Launch</a>
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.telegramParent}>
            {/* <img className={styles.telegramIcon} alt="" src="/telegram.svg" /> */}
            <img
              className={styles.telegramIcon}
              alt=""
              src="/x-twitter.svg"
              onClick={() => {
                window.open('https://twitter.com/rwa20_tweet?s=21')
              }}
            />
          </div>
        </div>
        <div className={styles.lightLine1}></div>
      </div>
      <div className={styles.main}>
        <div className={styles.inscribeTheWorld}>Inscribe the World</div>
        <div className={styles.theBrc20ExtensionContainer}>
          <span>{`The `}</span>
          <span className={styles.brc20Extension}>BRC-20 Extension</span>
          <span> for Real World Assets</span>
        </div>

        <div
          className={styles.mintNowParent}
          onClick={() => {
            window.open('https://avascriptions.com/token/detail?tick=RWA3')
          }}
        >
          <div className={styles.fairLaunch}>Get Now</div>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M6.89988 19.5L5.49988 18.1L15.0999 8.5H6.49988V6.5H18.4999V18.5H16.4999V9.9L6.89988 19.5Z"
              fill="#050505"
            />
          </svg>
        </div>

        <img className={styles.image18Icon} alt="" src="/image-18@2x.png" />

        <div className={styles.expandingEcosystemParent}>
          <div className={styles.expandingEcosystem}>Expanding Ecosystem</div>
          <div className={styles.frameParent}>
            <div className={styles.groupWrapper}>
              <img className={styles.frameChild} alt="" src="/group-12.svg" />
            </div>
            <div className={styles.groupWrapper}>
              <img className={styles.frameChild} alt="" src="/group-13.svg" />
            </div>
            <div className={styles.groupWrapper}>
              <img className={styles.frameChild} alt="" src="/group-11.svg" />
            </div>
          </div>
        </div>

        <div className={styles.frameGroup}>
          <div className={styles.frameDiv}>
            <div className={styles.frameParent1}>
              <a id="fairLaunch"></a>
              <div className={styles.inscriptionReleasedParent}>
                <div className={styles.inscriptionReleased}>Inscription Released</div>
                <div className={styles.theInauguralConcept}>
                  The inaugural concept inscription associated with the RWA-20 protocol, named RWAX, has been
                  successfully launched on the Avalanche Network. Holders are now benefiting from unique privileges and
                  incentives. Get your RWAX token right now!
                </div>
              </div>
              {/* <div className={styles.frameParent2}>
                <div className={styles.mintProgressParent}>
                  <div className={styles.fairLaunch}>Mint Progress</div>
                  <div className={styles.fairLaunch}>30%</div>
                </div>
                <div className={styles.rectangleContainer}>
                  <div className={styles.groupChild1} />
                  <div className={styles.groupChild2} />
                </div>
              </div> */}
            </div>
            <div
              className={styles.mintNowGroup}
              onClick={() => {
                window.open('https://avascriptions.com/token/detail?tick=RWA3')
              }}
            >
              <div className={styles.leanMore}>Get Now</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  d="M6.89988 19.5L5.49988 18.1L15.0999 8.5H6.49988V6.5H18.4999V18.5H16.4999V9.9L6.89988 19.5Z"
                  fill="white"
                  fill-opacity="0.7"
                />
              </svg>
            </div>
          </div>
          <img className={styles.image21Icon} alt="" src="/image-21@2x.png" />
          <div className={styles.lightLine1}></div>
          <div className={styles.lightLine2}></div>
        </div>

        <div className={styles.rwa20IsAnWrap}>
          <div className={styles.image12Parent}>
            <img className={styles.image12Icon} alt="" src="/image-12@2x.png" />
            <img className={styles.image14Icon} alt="" src="/image-14@2x.png" />
            <img className={styles.image9Icon} alt="" src="/image-9@2x.png" />
            <img className={styles.image7Icon} alt="" src="/image-7@2x.png" />
            <img className={styles.image8Icon} alt="" src="/image-8@2x.png" />
            <img className={styles.image10Icon} alt="" src="/image-10@2x.png" />
            <img className={styles.image11Icon} alt="" src="/image-11@2x.png" />
            <img className={styles.image13Icon} alt="" src="/image-13@2x.png" />
            <img className={styles.image15Icon} alt="" src="/image-15@2x.png" />
            <img className={styles.image16Icon} alt="" src="/image-16@2x.png" />
          </div>
          <div className={styles.aboutRwa20}>About RWA-20</div>
          <div className={styles.rwa20IsAn}>
            RWA-20 is an innovative protocol designed to extend inscription technology to Real-World Assets (RWA).
            Drawing inspiration from BRC20 and Ordinals, it uses a blockchain inscription approach instead of smart
            contracts. This strategy simplifies the process, mitigates the threat of smart contract vulnerabilities,
            thereby enhancing security. At the same time, it maintains transparent changes in asset ownership and user
            identity.
          </div>
          <div
            className={styles.leanMoreParent}
            onClick={() => {
              window.open('https://rwa-20.gitbook.io/rwa-20-docs/')
            }}
          >
            <div className={styles.leanMore}>Learn more</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M6.89988 19.5L5.49988 18.1L15.0999 8.5H6.49988V6.5H18.4999V18.5H16.4999V9.9L6.89988 19.5Z"
                fill="white"
                fill-opacity="0.7"
              />
            </svg>
          </div>
        </div>

        <div className={styles.supportedUseCasesWrap}>
          <div className={styles.supportedUseCasesWrapHeader}>
            <div className={styles.supportedUseCases}>Supported Use Cases</div>
            <div
              className={styles.leanMoreGroup}
              onClick={() => {
                window.open('https://rwa-20.gitbook.io/rwa-20-docs/')
              }}
            >
              <div className={styles.leanMore}>Learn more</div>
              <img
                className={styles.arrowOutwardFill0Wght400GrIcon}
                alt=""
                src="/arrow-outward-fill0-wght400-grad0-opsz24-1.svg"
              />
            </div>
          </div>
          <div className={styles.frameParent3}>
            <div className={styles.privateEquityAndVentureCapParent}>
              <div className={styles.inscriptionReleased}>Private Equity and Venture Capital</div>
              <div className={styles.theInauguralConcept}>
                The RWA-20 protocol facilitates groundbreaking opportunities in private equity and venture capital,
                offering secure, streamlined participation in high-potential startups and established private firms.
              </div>
            </div>
            <div className={styles.realEstateTransactionsParent}>
              <div className={styles.inscriptionReleased}>Real Estate Transactions</div>
              <div className={styles.theInauguralConcept}>
                RWA-20 introduces a more fluid and transparent market, enabling direct and efficient property
                transactions, thereby transforming real estate investment by lowering traditional entry barriers.
              </div>
            </div>
            <div className={styles.tokenizationOfArtAndPrecioParent}>
              <div className={styles.inscriptionReleased}>Tokenization of Art and Precious Items</div>
              <div className={styles.theInauguralConcept}>
                RWA-20 facilitates the tokenization of fine arts and precious collectibles, preserving their value and
                legacy, while enabling fractional ownership, enhanced accessibility, and ensuring the security and
                authenticity of each item.
              </div>
            </div>
            <div className={styles.tokenizationOfArtAndPrecioParent}>
              <div className={styles.inscriptionReleased}>Bonds and Other Financial Instruments</div>
              <div className={styles.theInauguralConcept}>
                Extending to bonds and a variety of financial instruments, RWA-20 enhances accessibility and liquidity.
                This protocol opens doors for a broader range of investors to engage in diverse investment opportunities
                with ease and trust.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.compatibleStandardsWrap}>
          <img className={styles.image5Icon} alt="" src="/image-5@2x.png" />
          <div className={styles.compatibleStandards}>Compatible Standards</div>

          <div className={styles.frameParent4}>
            <div className={styles.frameParent5}>
              <div className={styles.vectorWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="28" viewBox="0 0 56 28" fill="none">
                  <path
                    d="M36.5894 13.8323C35.6666 12.3314 34.3056 11.2949 32.5092 10.7257L37.6276 2.01867V0.346567H24.032L19.0168 17.1768L14.0049 0.346567H10.3731L12.0336 5.84395L8.64398 17.1768L3.63062 0.346567H0L8.29793 28H8.64398L13.83 10.8632L19.0168 28H19.3621L26.4672 4.3224L26.8011 3.76116H32.6484L27.8037 12.0208V13.6599H29.1893C30.9659 13.6599 32.2652 14.1951 33.0966 15.2656C33.8111 16.1748 34.1674 17.4609 34.1674 19.1225C34.1674 20.6255 33.8342 21.9116 33.166 22.9808C32.4978 24.0514 31.6793 24.5843 30.7109 24.5843C29.7903 24.5843 28.9873 24.2836 28.3058 23.6806C27.6273 23.0787 27.0774 22.253 26.6637 21.2082L23.827 22.3666C24.4023 24.0971 25.2913 25.4674 26.4892 26.4806C27.6865 27.4921 29.0964 28 30.7106 28C32.8515 28 34.5976 27.146 35.9462 25.4398C37.2959 23.7332 37.9729 21.6261 37.9729 19.1225C37.9733 17.097 37.51 15.3324 36.5894 13.8323Z"
                    fill="white"
                  />
                  <path
                    d="M52.0438 0.191666L52.6338 3.72163L50.5483 7.65465C50.5483 7.65465 49.7489 5.98472 48.4239 5.06142C47.3052 4.28323 46.5746 4.11417 45.4369 4.34708C43.9722 4.6453 42.3117 6.37218 41.588 8.5025C40.72 11.0522 40.7126 12.2853 40.6836 13.419C40.6347 15.2337 40.9242 16.308 40.9242 16.308C40.9242 16.308 39.6612 14.0006 39.6726 10.6187C39.6829 8.20537 40.0653 6.0152 41.1938 3.85514C42.1905 1.9581 43.6706 0.817844 44.9835 0.682885C46.3398 0.543573 47.414 1.19007 48.2439 1.88917C49.113 2.62237 49.991 4.22772 49.991 4.22772L52.0438 0.191666Z"
                    fill="white"
                  />
                  <path
                    d="M52.3012 20.1658C52.3012 20.1658 51.3787 21.7889 50.8068 22.4155C50.2326 23.0402 49.2084 24.1442 47.9417 24.6942C46.6729 25.2445 46.0101 25.3494 44.7563 25.2307C43.5059 25.1121 42.3424 24.3978 41.9347 24.0985C41.5284 23.8002 40.4887 22.9201 39.9006 22.1031C39.3113 21.2825 38.3911 19.6445 38.3911 19.6445C38.3911 19.6445 38.9025 21.2868 39.225 21.9834C39.4094 22.3839 39.9774 23.6094 40.7822 24.6778C41.5309 25.677 42.9904 27.3904 45.2097 27.7775C47.4289 28.1635 48.9516 27.1811 49.3281 26.9438C49.7047 26.7058 50.5 26.047 51.0026 25.5159C51.5283 24.9623 52.0246 24.2545 52.3015 23.8307C52.5043 23.5202 52.8302 22.8911 52.8302 22.8911L52.3012 20.1658Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M54.7793 0.657016C55.0195 0.657016 55.1308 0.723769 55.1308 0.88884C55.1308 1.04702 55.0195 1.10398 54.7862 1.10398H54.5684V0.657016H54.7793ZM54.8256 0.492308H54.3109V1.84189H54.568V1.26614H54.8219L55.1003 1.84153H55.3872L55.0831 1.22587C55.2803 1.18669 55.3946 1.05427 55.3946 0.862356C55.3946 0.617472 55.2072 0.492308 54.8256 0.492308ZM55.8391 1.19975C55.8391 1.47475 55.7322 1.72943 55.5349 1.91735C55.3281 2.11616 55.0702 2.22174 54.7859 2.22174C54.5181 2.22174 54.2543 2.1129 54.0563 1.91445C53.859 1.71564 53.7492 1.46133 53.7492 1.19032C53.7492 0.918589 53.8634 0.654113 54.067 0.449499C54.258 0.257219 54.5151 0.154549 54.7954 0.154549C55.0831 0.154549 55.341 0.260484 55.5419 0.462559C55.7359 0.654113 55.8391 0.912421 55.8391 1.19975ZM54.7925 0C54.4674 0 54.1768 0.114642 53.9564 0.337034C53.7227 0.572485 53.592 0.876142 53.592 1.19032C53.592 1.50486 53.7158 1.79509 53.9431 2.02293C54.1739 2.25475 54.4722 2.37955 54.7925 2.37955C55.107 2.37955 55.4111 2.25475 55.6488 2.02655C55.8766 1.80852 56 1.51756 56 1.19032C56 0.872514 55.8725 0.574662 55.6517 0.353359C55.4218 0.121535 55.1205 0 54.7925 0Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={styles.expandingEcosystem}>DID Core</div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.vectorWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36" fill="none">
                  <path
                    d="M21.467 2.44446C21.467 3.79558 20.3737 4.88892 19.0226 4.88892C17.6715 4.88892 16.5781 3.79558 16.5781 2.44446C16.5781 1.09334 17.6715 0 19.0226 0C20.3737 0 21.467 1.09334 21.467 2.44446Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M5.3776 2.48897C5.3776 3.46675 4.57759 4.26676 3.59981 4.26676C2.62203 4.26676 1.82202 3.46675 1.82202 2.48897C1.82202 1.51119 2.62203 0.711182 3.59981 0.711182C4.57759 0.711182 5.3776 1.51119 5.3776 2.48897Z"
                    fill="#C0C0C0"
                  />
                  <path
                    d="M29.2267 2.63996C29.2267 3.86663 28.2311 4.8622 27.0045 4.8622C25.7778 4.8622 24.7822 3.86663 24.7822 2.63996C24.7822 1.41329 25.7778 0.417725 27.0045 0.417725C28.2311 0.417725 29.2267 1.41329 29.2267 2.63996Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M36.4889 2.64009C36.4889 3.61788 35.6889 4.41788 34.7111 4.41788C33.7334 4.41788 32.9333 3.61788 32.9333 2.64009C32.9333 1.65342 33.7334 0.862305 34.7111 0.862305C35.6889 0.862305 36.4889 1.65342 36.4889 2.64009Z"
                    fill="#C0C0C0"
                  />
                  <path
                    d="M13.3781 2.5777C13.3781 3.80438 12.3825 4.79994 11.1558 4.79994C9.92916 4.79994 8.93359 3.80438 8.93359 2.5777C8.93359 1.35103 9.92916 0.355469 11.1558 0.355469C12.3825 0.355469 13.3781 1.35103 13.3781 2.5777Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M19.0673 21.5379C17.1028 21.5379 15.5117 19.9468 15.5117 17.9823C15.5117 16.0179 17.1028 14.4268 19.0673 14.4268C21.0318 14.4268 22.6229 16.0179 22.6229 17.9823C22.6229 19.9468 21.0318 21.5379 19.0673 21.5379Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M19.0672 13.0934C17.3516 13.0934 15.9561 11.6978 15.9561 9.98222C15.9561 8.26666 17.3516 6.87109 19.0672 6.87109C20.7827 6.87109 22.1783 8.26666 22.1783 9.98222C22.1783 11.6978 20.7827 13.0934 19.0672 13.0934Z"
                    fill="#E6E6E6"
                  />
                  <path
                    d="M3.37756 20.4446C2.02645 20.4446 0.933105 19.3512 0.933105 18.0001C0.933105 16.649 2.02645 15.5557 3.37756 15.5557C4.72868 15.5557 5.82202 16.649 5.82202 18.0001C5.82202 19.3512 4.72868 20.4446 3.37756 20.4446Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M3.44892 28.1156C2.22225 28.1156 1.22668 27.1201 1.22668 25.8934C1.22668 24.6667 2.22225 23.6711 3.44892 23.6711C4.67559 23.6711 5.67115 24.6667 5.67115 25.8934C5.67115 27.1201 4.67559 28.1156 3.44892 28.1156Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M3.44869 35.3781C2.4709 35.3781 1.6709 34.5781 1.6709 33.6003C1.6709 32.6225 2.4709 31.8225 3.44869 31.8225C4.43536 31.8225 5.22648 32.6225 5.22648 33.6003C5.22648 34.5781 4.43536 35.3781 3.44869 35.3781Z"
                    fill="#C0C0C0"
                  />
                  <path
                    d="M3.5113 12.2667C2.28462 12.2667 1.28906 11.2712 1.28906 10.0445C1.28906 8.81783 2.28462 7.82227 3.5113 7.82227C4.73797 7.82227 5.73353 8.81783 5.73353 10.0445C5.73353 11.2712 4.73797 12.2667 3.5113 12.2667Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M34.6226 20.4446C33.2714 20.4446 32.1781 19.3424 32.1781 18.0001C32.1781 16.649 33.2714 15.5557 34.6226 15.5557C35.9737 15.5557 37.067 16.649 37.067 18.0001C37.067 19.3424 35.9737 20.4446 34.6226 20.4446Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M34.5604 28.1156C33.3337 28.1156 32.3381 27.1201 32.3381 25.8934C32.3381 24.6667 33.3337 23.6711 34.5604 23.6711C35.787 23.6711 36.7826 24.6667 36.7826 25.8934C36.7826 27.1201 35.787 28.1156 34.5604 28.1156Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M34.5603 35.3778C33.5825 35.3778 32.7825 34.5778 32.7825 33.6001C32.7825 32.6223 33.5825 31.8223 34.5603 31.8223C35.5469 31.8223 36.338 32.6223 36.338 33.6001C36.338 34.5778 35.5469 35.3778 34.5603 35.3778Z"
                    fill="#C0C0C0"
                  />
                  <path
                    d="M34.6227 12.2667C33.3961 12.2667 32.4005 11.2712 32.4005 10.0445C32.4005 8.81783 33.3961 7.82227 34.6227 7.82227C35.8494 7.82227 36.845 8.81783 36.845 10.0445C36.845 11.2712 35.8494 12.2667 34.6227 12.2667Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M16.6664 33.5555C16.6664 32.2044 17.7597 31.1111 19.1108 31.1111C20.462 31.1111 21.5553 32.2044 21.5553 33.5555C21.5553 34.9067 20.462 36 19.1108 36C17.7597 36 16.6664 34.9067 16.6664 33.5555Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M9.08423 33.4488C9.08423 32.2221 10.0798 31.2266 11.3065 31.2266C12.5331 31.2266 13.5287 32.2221 13.5287 33.4488C13.5287 34.6755 12.5331 35.671 11.3065 35.671C10.0798 35.671 9.08423 34.6755 9.08423 33.4488Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M24.9336 33.5113C24.9336 32.2846 25.9292 31.2891 27.1558 31.2891C28.3825 31.2891 29.3781 32.2846 29.3781 33.5113C29.3781 34.738 28.3825 35.7335 27.1558 35.7335C25.9292 35.7335 24.9336 34.738 24.9336 33.5113Z"
                    fill="#CDCDCD"
                  />
                  <path
                    d="M11.1551 21.0667C9.43951 21.0667 8.04395 19.6712 8.04395 17.9556C8.04395 16.24 9.43951 14.8445 11.1551 14.8445C12.8706 14.8445 14.2662 16.24 14.2662 17.9556C14.2662 19.6712 12.8706 21.0667 11.1551 21.0667Z"
                    fill="#E6E6E6"
                  />
                  <path
                    d="M11.1551 12.7113C9.67952 12.7113 8.4884 11.5202 8.4884 10.0446C8.4884 8.56905 9.67952 7.37793 11.1551 7.37793C12.6306 7.37793 13.8218 8.56905 13.8218 10.0446C13.8218 11.5202 12.6306 12.7113 11.1551 12.7113Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M11.1551 28.6224C9.67952 28.6224 8.4884 27.4313 8.4884 25.9557C8.4884 24.4802 9.67952 23.2891 11.1551 23.2891C12.6306 23.2891 13.8218 24.4802 13.8218 25.9557C13.8218 27.4313 12.6306 28.6224 11.1551 28.6224Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M27.1552 21.1559C25.4396 21.1559 24.0441 19.7603 24.0441 18.0447C24.0441 16.3292 25.4396 14.9336 27.1552 14.9336C28.8708 14.9336 30.2663 16.3292 30.2663 18.0447C30.2663 19.7603 28.8708 21.1559 27.1552 21.1559Z"
                    fill="#E6E6E6"
                  />
                  <path
                    d="M27.1551 12.7999C25.6795 12.7999 24.4884 11.6088 24.4884 10.1332C24.4884 8.65767 25.6795 7.46655 27.1551 7.46655C28.6307 7.46655 29.8218 8.65767 29.8218 10.1332C29.8218 11.6088 28.6307 12.7999 27.1551 12.7999Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M27.1551 28.7113C25.6795 28.7113 24.4884 27.5202 24.4884 26.0446C24.4884 24.569 25.6795 23.3779 27.1551 23.3779C28.6307 23.3779 29.8218 24.569 29.8218 26.0446C29.8218 27.5202 28.6307 28.7113 27.1551 28.7113Z"
                    fill="#DADADA"
                  />
                  <path
                    d="M19.1554 29.1822C17.4399 29.1822 16.0443 27.7867 16.0443 26.0711C16.0443 24.3555 17.4399 22.96 19.1554 22.96C20.871 22.96 22.2666 24.3555 22.2666 26.0711C22.2666 27.7867 20.871 29.1822 19.1554 29.1822Z"
                    fill="#E6E6E6"
                  />
                </svg>
              </div>
              <div className={styles.expandingEcosystem}>DIDComm</div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.vectorWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="46" viewBox="0 0 40 46" fill="none">
                  <path
                    d="M37.5232 33.076L20 43.1516L2.4768 33.076V12.924L20 2.84836L37.5232 12.924V33.076ZM20 0L0 11.5002V34.4998L20 46L40 34.4998V11.5002L20 0Z"
                    fill="white"
                  />
                  <path
                    d="M24.7351 32.1454L15.2645 26.7001H12.7877V36.1351L15.2645 37.5597V29.5485L24.7351 34.9938V37.5597L27.2119 36.1355V26.7001H24.7351V32.1454Z"
                    fill="white"
                  />
                  <path
                    d="M7.44879 26.7001H4.97199V31.6411L10.2928 34.7006V31.8522L7.44879 30.2169V26.7001Z"
                    fill="white"
                  />
                  <path
                    d="M32.5513 19.2999H35.0281V14.3589L29.7073 11.2994V14.1478L32.5513 15.7831V19.2999Z"
                    fill="white"
                  />
                  <path
                    d="M15.2648 11.2887L20 8.5662L24.7352 11.2887V12.8085H15.2648V11.2887ZM12.788 19.2999H15.2648V15.2784L22.2584 19.2999H27.212L20.2124 15.2752H27.212V9.86489L20 5.71784L12.788 9.86449V19.2999Z"
                    fill="white"
                  />
                  <path
                    d="M7.44879 21.7667V15.7831L10.2928 14.1478V11.2994L4.97199 14.3589V24.2334H32.5512V30.2169L29.7072 31.8522V34.7006L35.028 31.6411V21.7667H7.44879Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={styles.expandingEcosystem}>Legal DID</div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.vectorWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="28" viewBox="0 0 56 28" fill="none">
                  <path
                    d="M36.5894 13.8323C35.6666 12.3314 34.3056 11.2949 32.5092 10.7257L37.6276 2.01867V0.346567H24.032L19.0168 17.1768L14.0049 0.346567H10.3731L12.0336 5.84395L8.64398 17.1768L3.63062 0.346567H0L8.29793 28H8.64398L13.83 10.8632L19.0168 28H19.3621L26.4672 4.3224L26.8011 3.76116H32.6484L27.8037 12.0208V13.6599H29.1893C30.9659 13.6599 32.2652 14.1951 33.0966 15.2656C33.8111 16.1748 34.1674 17.4609 34.1674 19.1225C34.1674 20.6255 33.8342 21.9116 33.166 22.9808C32.4978 24.0514 31.6793 24.5843 30.7109 24.5843C29.7903 24.5843 28.9873 24.2836 28.3058 23.6806C27.6273 23.0787 27.0774 22.253 26.6637 21.2082L23.827 22.3666C24.4023 24.0971 25.2913 25.4674 26.4892 26.4806C27.6865 27.4921 29.0964 28 30.7106 28C32.8515 28 34.5976 27.146 35.9462 25.4398C37.2959 23.7332 37.9729 21.6261 37.9729 19.1225C37.9733 17.097 37.51 15.3324 36.5894 13.8323Z"
                    fill="white"
                  />
                  <path
                    d="M52.0438 0.191666L52.6338 3.72163L50.5483 7.65465C50.5483 7.65465 49.7489 5.98472 48.4239 5.06142C47.3052 4.28323 46.5746 4.11417 45.4369 4.34708C43.9722 4.6453 42.3117 6.37218 41.588 8.5025C40.72 11.0522 40.7126 12.2853 40.6836 13.419C40.6347 15.2337 40.9242 16.308 40.9242 16.308C40.9242 16.308 39.6612 14.0006 39.6726 10.6187C39.6829 8.20537 40.0653 6.0152 41.1938 3.85514C42.1905 1.9581 43.6706 0.817844 44.9835 0.682885C46.3398 0.543573 47.414 1.19007 48.2439 1.88917C49.113 2.62237 49.991 4.22772 49.991 4.22772L52.0438 0.191666Z"
                    fill="white"
                  />
                  <path
                    d="M52.3012 20.1658C52.3012 20.1658 51.3787 21.7889 50.8068 22.4155C50.2326 23.0402 49.2084 24.1442 47.9417 24.6942C46.6729 25.2445 46.0101 25.3494 44.7563 25.2307C43.5059 25.1121 42.3424 24.3978 41.9347 24.0985C41.5284 23.8002 40.4887 22.9201 39.9006 22.1031C39.3113 21.2825 38.3911 19.6445 38.3911 19.6445C38.3911 19.6445 38.9025 21.2868 39.225 21.9834C39.4094 22.3839 39.9774 23.6094 40.7822 24.6778C41.5309 25.677 42.9904 27.3904 45.2097 27.7775C47.4289 28.1635 48.9516 27.1811 49.3281 26.9438C49.7047 26.7058 50.5 26.047 51.0026 25.5159C51.5283 24.9623 52.0246 24.2545 52.3015 23.8307C52.5043 23.5202 52.8302 22.8911 52.8302 22.8911L52.3012 20.1658Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M54.7793 0.657016C55.0195 0.657016 55.1308 0.723769 55.1308 0.88884C55.1308 1.04702 55.0195 1.10398 54.7862 1.10398H54.5684V0.657016H54.7793ZM54.8256 0.492308H54.3109V1.84189H54.568V1.26614H54.8219L55.1003 1.84153H55.3872L55.0831 1.22587C55.2803 1.18669 55.3946 1.05427 55.3946 0.862356C55.3946 0.617472 55.2072 0.492308 54.8256 0.492308ZM55.8391 1.19975C55.8391 1.47475 55.7322 1.72943 55.5349 1.91735C55.3281 2.11616 55.0702 2.22174 54.7859 2.22174C54.5181 2.22174 54.2543 2.1129 54.0563 1.91445C53.859 1.71564 53.7492 1.46133 53.7492 1.19032C53.7492 0.918589 53.8634 0.654113 54.067 0.449499C54.258 0.257219 54.5151 0.154549 54.7954 0.154549C55.0831 0.154549 55.341 0.260484 55.5419 0.462559C55.7359 0.654113 55.8391 0.912421 55.8391 1.19975ZM54.7925 0C54.4674 0 54.1768 0.114642 53.9564 0.337034C53.7227 0.572485 53.592 0.876142 53.592 1.19032C53.592 1.50486 53.7158 1.79509 53.9431 2.02293C54.1739 2.25475 54.4722 2.37955 54.7925 2.37955C55.107 2.37955 55.4111 2.25475 55.6488 2.02655C55.8766 1.80852 56 1.51756 56 1.19032C56 0.872514 55.8725 0.574662 55.6517 0.353359C55.4218 0.121535 55.1205 0 54.7925 0Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={styles.expandingEcosystem}>Verifiable Credentials</div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.vectorWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="46" viewBox="0 0 30 46" fill="none">
                  <path d="M14.9963 0V17.0043L29.3685 23.4265L14.9963 0Z" fill="white" fill-opacity="0.6" />
                  <path d="M14.9959 0L0.621826 23.4265L14.9959 17.0043V0Z" fill="white" />
                  <path d="M14.9963 34.4461V46.0002L29.3781 26.103L14.9963 34.4461Z" fill="white" fill-opacity="0.6" />
                  <path d="M14.9959 46.0002V34.4442L0.621826 26.103L14.9959 46.0002Z" fill="white" />
                  <path d="M14.9963 31.7713L29.3685 23.4264L14.9963 17.0081V31.7713Z" fill="white" fill-opacity="0.2" />
                  <path
                    d="M0.621826 23.4264L14.9959 31.7713V17.0081L0.621826 23.4264Z"
                    fill="white"
                    fill-opacity="0.6"
                  />
                </svg>
              </div>
              <div className={styles.expandingEcosystem}>ERC3643</div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.vectorWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="46" viewBox="0 0 30 46" fill="none">
                  <path d="M14.9963 0V17.0043L29.3685 23.4265L14.9963 0Z" fill="white" fill-opacity="0.6" />
                  <path d="M14.9959 0L0.621826 23.4265L14.9959 17.0043V0Z" fill="white" />
                  <path d="M14.9963 34.4461V46.0002L29.3781 26.103L14.9963 34.4461Z" fill="white" fill-opacity="0.6" />
                  <path d="M14.9959 46.0002V34.4442L0.621826 26.103L14.9959 46.0002Z" fill="white" />
                  <path d="M14.9963 31.7713L29.3685 23.4264L14.9963 17.0081V31.7713Z" fill="white" fill-opacity="0.2" />
                  <path
                    d="M0.621826 23.4264L14.9959 31.7713V17.0081L0.621826 23.4264Z"
                    fill="white"
                    fill-opacity="0.6"
                  />
                </svg>
              </div>
              <div className={styles.expandingEcosystem}>ERC1400</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.vectorGroup}>
          <svg
            className={styles.vectorIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="33"
            viewBox="0 0 49 33"
            fill="none"
          >
            <path
              d="M32.7462 8.30876V0.75H32.7519C41.6025 0.75 48.75 7.79383 48.75 16.5C48.75 25.1895 41.6024 32.25 32.7519 32.25C29.7966 32.25 27.0267 31.4564 24.6429 30.0678L24.6417 30.0671C22.1409 28.6293 20.0765 26.5303 18.6835 24.0184C17.496 21.8557 16.7975 19.383 16.7558 16.75H24.0625H24.3125H24.4413C24.552 20.3821 27.0654 23.4136 30.4647 24.362C31.1901 24.5881 31.9645 24.6912 32.7519 24.6912C37.3536 24.6912 41.0663 21.0233 41.0663 16.5C41.0663 11.9767 37.3536 8.30876 32.7519 8.30876L32.7462 8.30876ZM30.3164 8.9813C31.504 11.144 32.2025 13.6169 32.2442 16.25H24.9375H24.6875H24.5587C24.448 12.6168 21.9339 9.56821 18.5327 8.62043C17.8098 8.4122 17.0365 8.30876 16.2481 8.30876C11.6638 8.30876 7.93372 11.9764 7.93372 16.5C7.93372 20.9411 11.5289 24.5571 15.9981 24.6876V32.2481C7.27975 32.1164 0.25 25.1073 0.25 16.5C0.25 7.81062 7.41453 0.75 16.2481 0.75C19.2208 0.75 22.0076 1.54373 24.391 2.93204C26.8761 4.38717 28.9406 6.48674 30.3164 8.9813Z"
              fill="url(#paint0_radial_625_1801)"
              stroke="url(#paint1_linear_625_1801)"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <defs>
              <radialGradient
                id="paint0_radial_625_1801"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(3.07541 16.4404) rotate(-15.1119) scale(47.556 41.4133)"
              >
                <stop stop-color="#8C96A8" />
                <stop offset="0.422395" stop-color="#5D7297" />
                <stop offset="1" stop-color="#363E64" />
              </radialGradient>
              <linearGradient
                id="paint1_linear_625_1801"
                x1="24.5"
                y1="0.5"
                x2="24.5"
                y2="32.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" stop-opacity="0.13" />
                <stop offset="1" stop-color="white" stop-opacity="0.06" />
              </linearGradient>
            </defs>
          </svg>
          <div className={styles.rwa20}>RWA-20</div>
        </div>
        <div className={styles.copyrightRwa20Wrapper}>
          <div className={styles.fairLaunch}>Copyright © RWA-20</div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.telegramParent}>
            {/* <img className={styles.telegramIcon} alt="" src="/telegram-2019-logo-1.svg" /> */}
            <img
              className={styles.telegramIcon}
              alt=""
              src="/frame-30.svg"
              onClick={() => {
                window.open('https://twitter.com/rwa20_tweet?s=21')
              }}
            />
          </div>
        </div>
        <div className={styles.lightLine2}></div>
      </div>
    </div>
  )
}

export default RWA20WebsiteDefault
